import { BaseModel } from "./base.model";
import { Collaboration } from "./collaboration.model";

export type UserRole = "nurse" | "employee" | "admin" | "opticien" | "medecin" | "nurseSub"| "taxi";
export class User extends BaseModel {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  role: UserRole;
  archived: boolean;
  phone: string;
  gestanId: string;
  titularNurseId: string; // titulaire id si on est dans le cas d'un remplaçant
  titularNurseName: string // titulaire name si on est dans le cas d'un remplaçant
  nursesInCharge: Array<string>;
  oneSignalId: string;
  private _color: string;
  appointment: Date;
  codeParrainage: string;
  nombreTrefles: number;
  collaborators: Array<Collaboration>;
  isArchived: boolean;
  totalTime: string;
  agreementNumber: string;
  get color() {
    return this._color;
  }
  set color(color) {
    this._color = color;
  }
  constructor(arg?) {
    super();
    if (arg) {
      for (const key in arg) {
        if (Object.prototype.hasOwnProperty.call(arg, key)) {
          (this as any)[key] = arg[key];
        }
      }
    }
    this._color = this._getRandomColor();
  }
  get name(): string {
    return (this.lastname || "") + " " + (this.firstname || "");
  }

  get fullname(): string {
    return (this.firstname || "") + " " + (this.lastname || "");
  }
  
  signupRequired() {
    return this.email && this.password && this.firstname && this.lastname;
  }
  private _getRandomColor() {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }
    return color;
  }

  
}
