import moment from 'moment-timezone';

export class BaseModel {
  _id: string;
  createdAt: string;
  lastUpdatedAt: string;

  constructor(arg?: Partial<BaseModel>) {
    if (arg) {
      for (const key in arg) {
        if (Object.prototype.hasOwnProperty.call(arg, key)) {
          (this as any)[key] = arg[key];
        }
      }
    }
  }
  public get createdAtHumanDate(): string {
    const d = this.createdAt;
    if (!d) {
      return "N/A";
    }
    return moment(d).format("DD/MM/YYYY à HH[h]mm");
  }
}
