import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { MessageModel } from '../models/message.model';
import { User } from '../models/user';
@Injectable()
export class SessionService {
    public token: string;
    public tokenExpiration: string;
    public user_id: string;
    public sessionActive: boolean;
    public user: User;

    public type: string;
    isLoading: boolean;
  
    unreadMessages: MessageModel[] = [];

    constructor(private _rest: RestService<User>) {
        this.user = new User();
        this.isLoading = false;
    }
    get isAdmin(): boolean {
        return this.type === 'admin';
    }
    get isEmployee(): boolean {
        return this.type === 'employee';
    }
    get isNurse(): boolean {
        return this.type === 'nurse';
    }
    get isNurseSub(): boolean {
        return this.type === 'nurseSub';
    }
    get isOpticien(): boolean {
        return this.type === 'opticien';
    }
    get isMedecin(): boolean {
        return this.type === 'medecin';
    }
    get isTaxi(): boolean {
        return this.type === 'taxi';
    }
    getSessionStatus() {
        return new Promise<boolean>((resolve) => {
            const token: string = localStorage.getItem('token');
            const tokenExpiration: number = parseInt(localStorage.getItem('tokenExpiration'), 10);
            const user: User = JSON.parse(localStorage.getItem('user_data'));
            if (!user) {
                return resolve(false);
            }
            if (!user._id) {
                return resolve(false);
            }
            this.user = user;
            if (user && user._id) {
                this.user_id = user._id;
            }
            this.type = user.role;
            const now = Date.now();
            if (token && tokenExpiration) {
                let sess: ISessionToken;
                if (tokenExpiration * 1000 > now) {
                    sess = {
                        token: token,
                        tokenExpiration: tokenExpiration,
                        user: user
                    };
                    this.initSession(sess);
                    return resolve(true);
                } else {
                    // Expired, call the online server for it
                    return this._rest.get('/regenerate?token='+token).subscribe({
                        next: data => {
                        sess = {
                            token: data.token,
                            tokenExpiration: data.tokenExpiration,
                            user: user
                        };
                        this.initSession(sess);
                        resolve(true);
                    }, 
                    error: () => {
                        return resolve(false);
                    }
                });
                }
            }
            resolve(false);
        });
    }
    initSession(data: ISessionToken, callback?: () => void) {
        this.sessionActive = true;
        localStorage.setItem('token', data.token);
        localStorage.setItem('tokenExpiration', data.tokenExpiration.toString());
        localStorage.setItem('user_data', JSON.stringify(data.user));
        localStorage.setItem('user_id', data.user._id);
        this.type = data.user.role;
        this.user = data.user;
        this.user_id = data.user._id;

        if (callback) {
            callback();
        }
    }
    signin(data: ISessionToken, callback: () => void) {
        this.initSession(data, callback);
    }
    signout(callback: () => void) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('user_data');
        localStorage.removeItem('user_id');
        localStorage.removeItem('ps_params');
        localStorage.removeItem('patient_params');
        
        callback();
    }
    getUserId() {
        if (!this.user_id) {
            this.user_id = localStorage.getItem('user_id');
        }
        return this.user_id;
    }

    updateMessagesNotif(messages: MessageModel[]) {
        this.unreadMessages = messages || []
    }
}

export interface ISessionToken {
    token: string;
    tokenExpiration: number ;
    user: User;
}

