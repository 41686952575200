import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from './session.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _session: SessionService) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    
      const roles = route.data['roles'] as Array<string>;
      const sessionStatus = await this._session.getSessionStatus();

      const currentUserType = this._session.type;
      // route for admin
      if (roles && roles.indexOf(currentUserType) > -1) {
        if (sessionStatus === true) {
          return true;
        }
        this.router.navigate(['/pages/login']);
        return false;

      } else {
        this.router.navigate(['/pages/login']);
        return false;
      }
    }
}
