import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { lastValueFrom, map, Observable, of } from 'rxjs';
import { ParamModel } from '../models/utilitary-model/param.model';


@Injectable()
export class RestService <T> {
    private serverUrl: string = environment.SERVER_URL;
    
    constructor(private http: HttpClient) { }
    post(resource: string, data: T):Observable<any>  {
       
        return this.http.post(this.serverUrl + resource, data, this.createOptions());
            // .map(res => res.json());
    }
    get(resource: string, search?: ParamModel): Observable<any> {

        return this.http.get(this.serverUrl + resource, this.createOptions(search));
    }

    put(resource: string, data: T): Observable<any> {
        
        return this.http.put(this.serverUrl + resource, data, this.createOptions());
            // .map(res => res.json());
    }
    delete(resource: string): Observable<any> {
        
        return this.http.delete(this.serverUrl + resource, this.createOptions());
            // .map(res => res.json());
    }
    getFile(path: string) {
        const token = localStorage.getItem('token');
        return environment.DATA_ENDPOINT + '?token=' + token + '&path=' + path;
        
    }

    getExternalFile(path: string): Observable<any> {
        const token = localStorage.getItem('token');
        const url = environment.DATA_ENDPOINT + '?token=' + token + '&path=' + path
       return this.http.get(url,
        { responseType: 'blob' });
    }

    getFilePreview(path: string) {
        const token = localStorage.getItem('token');
        return environment.SERVER_URL + '/previewFile?token=' + token + '&path=' + path;
    }

    getFilePreviewObs(path: string): Observable<string>  {
        const token = localStorage.getItem('token');
        return of(environment.SERVER_URL + '/previewFile?token=' + token + '&path=' + path);
    }

    downloadFile(path: string, finalName: string = undefined) {
        const token = localStorage.getItem('token');
        let url = environment.SERVER_URL + '/downloadFile?token=' + token + '&path=' + path;

        if (finalName) {
            url += `&finalName=${finalName}`;
        }
        return url;
    }
    private createOptions(search?: ParamModel): object {
        const httpOptions: {[k: string]: any} = {}; 
        const token = localStorage.getItem('token'); 
        const httpheaders : HttpHeaders = new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + token
          })
        httpOptions.headers = httpheaders;
        if(search) {
            const obj = {}
            // verify object change array to string (for example for status)
            for (const prop in search) {
                obj[prop] = search[prop];
                if (Object.prototype.hasOwnProperty.call(search, prop) && Array.isArray(search[prop])) {
                    obj[prop] = search[prop].toString();
                }
            }
            
            httpOptions.params = new HttpParams({ fromObject: obj });
        }
       
        return httpOptions;
    }
     

    upload(file: File, path): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
    
        formData.append('file', file);
        const token = localStorage.getItem('token'); 
        const httpheaders : HttpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'path': path
          })

        const req = new HttpRequest('POST', environment.FILE_ENDPOINT, formData, {
          reportProgress: true,
          responseType: 'json',
          headers : httpheaders
        });
    
        return this.http.request(req);
      }

      loadAllNurses(): Promise<any> {
        const params = {
            limit: 10000,
            page: 1,
            without_archived: true,
            role: 'nurse',
            sortField: 'lastname=asc',
        };
   
        const observable = this.get('/users', params).pipe(map(
            (res) => {
                const data = {};
                for (const row of res.rows) {
                    data[row._id] = row.firstname + ' ' + row.lastname;
                }
                return data;
            }
        ));
        return lastValueFrom(observable);
    }

    loadAllPSUnaffected(): Promise<any> {
        const params = {
            limit: 10000,
            page: 1,
            without_archived: true,
            role: 'nurse,opticien,medecin,taxi',
            unaffected: true,
            sortField: 'lastname=asc',
        };
   
        const observable = this.get('/users', params).pipe(map(
            (res) => {
                const data = {};
                for (const row of res.rows) {
                    data[row._id] = row.firstname + ' ' + row.lastname;
                }
                return data;
            }
        ));
        return lastValueFrom(observable);
    }
}