import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute, NavigationEnd, Event } from "@angular/router";
import moment from 'moment-timezone';
import swal, { SweetAlertResult } from 'sweetalert2';
import { StatusValuesConsultation } from "../models/utilitary-model/status-values-consultation.model";
import { StatusValuesTeledeclaration } from "../models/utilitary-model/status-values-teledeclaration.model";
import { BaseStatusValues } from "../models/utilitary-model/base-status-values.model";
import { ParamModel } from "../models/utilitary-model/param.model";
import { StatusValuesTransport } from "../models/utilitary-model/status-values-transport.model";




@Injectable()
export class UtilsService {
  public weekday: Array<string> = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
  ];
  public statusValues: StatusValuesConsultation = {
    future: "A venir",
    waiting: "En attente",
    valid: "Validé",
    refused: "Refusé"
  };

  public statusValuesTeledeclaration: StatusValuesTeledeclaration = {
    waiting: "En attente de validation",
    valid: "En attente de télétransmission",
    refused: "Refusée",
    sent: "Télétransmise"
  };

  public statusValuesTransport: StatusValuesTransport = {
    billed: "Facturé",
    transmitted: "Transmis",
    refused: "Refusée",
    draft: "Brouillon"
  };

  public statusValuesOrdinance: BaseStatusValues = {
    waiting: 'En attente',
    valid: 'Validé',
    refused: 'Refusé'
  };

  public statusValuesDocument: BaseStatusValues = {
    waiting: 'En attente',
    valid: 'Traitée',
    refused: 'Refusée'
  };


  currentUrl: string;
  currentParams: ParamModel;
  previousUrl: string;
  previousParams: ParamModel;
  constructor(
    private _location: Location,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const urlTree = this._router.parseUrl(event.url);
        if (!urlTree.root.children["primary"]) {
          return;
        }
        const url = urlTree.root.children["primary"].segments
          .map(it => it.path)
          .join("/");
        if (this.currentUrl !== url) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = url;
          this.previousParams = this.currentParams;
        } else {
          const paramsAsMap = this.route.snapshot.queryParamMap;
          const keys: string[] = paramsAsMap.keys;
          const params = new ParamModel();
          for (const key of keys) {
            params[key] = paramsAsMap.get(key);
          }
          this.currentParams = params;
        }
      }
    });
  }
  toastError(error?) {
    if (!error) {
      error = "Une erreur s'est produite pendant le traitement";
    }
    this.showNotification(error, "danger");
  }
  toastSuccess(success?) {
    if (!success) {
      success = "Traitement réussi avec succès";
    }
    this.showNotification(success, "success");
  }
  toastWarning(warn) {
    this.showNotification(warn, "warning");
  }
  toastInfo(info) {
    this.showNotification(info, "info");
  }
  confirm(text?: string): Promise<SweetAlertResult<any>> {

    return swal.fire({
      title: 'Êtes-vous sûr?',
      html: text ? text.replace(/\n/g, "<br>") : "Voulez-vous vraiment continuer à réaliser cette action?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI, continuer',
      cancelButtonText: 'Annuler',
      customClass:{
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-default",
      },
      buttonsStyling: false
    });
  }

  popupSuccess(message) {
    return swal.fire({
      title: "Succès!",
      text: message,
      icon: "success",
      customClass:{
        confirmButton: "btn btn-success"
      },
      buttonsStyling: false
    });
  }

  popupForbidden(message) {
    return swal.fire({
      title: "Interdit!",
      text: message,
      icon: "error",
      customClass:{
        confirmButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
  }

  back() {
    // if (this.previousUrl && !force) {
    //   return this._router.navigate(["/" + this.previousUrl], {
    //     queryParams: this.previousParams
    //   });
    // } else {
    //   return this._location.back();
    // }
    return this._location.back();
  }
  showNotification(message, type?, from?, align?) {
    // var type = ['','info','success','warning','danger','rose','primary'];
    if (!type) {
      type = "info";
    }
    if (!from) {
      from = "top";
    }
    if (!align) {
      align = "right";
    }
    $.notify(
      {
        icon: "notifications",
        message: message
      },
      {
        type: type,
        timer: 3000,
        placement: {
          from: from,
          align: align
        }
      }
    );
  }

  buildDate(d: string): string {
    if (!d) {
      return "N/A";
    }
    return moment(d).format("DD/MM/YYYY à HH[h]mm");

  }
  removeAccents(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
}
