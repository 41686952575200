import { SearchFilterModel } from "./search-filter.model";

export class ParamModel extends SearchFilterModel  {
    nurse_id?: string;
    role?: string;
    sortField?: string;
    limit?: number;
    year?: number | string;
    query?: string;
    sessiontoken?: string;
    placeId?: string;
    patient_id?:string;
}